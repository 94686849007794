jQuery(document).ready(function($) {


    // Swipers
    var project_top_slider = new Swiper(".project-top-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });


});